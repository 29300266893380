@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500&display=swap');

html, body {  
  height: 100%;
}

#root {
  font-family: 'Kanit', sans-serif !important;
  height: 100%;  
}

* {
  box-sizing: border-box;
}

button {
  font-family: inherit !important;
}

h1,h2,h3,h4 {
  font-family: inherit !important;
}